<template>
	<div class="home-page">
		<div class="header1-box">
			<div>
				<p>存入总金额</p>
				<h2>{{homeInfo.count.total_inamount}}</h2>
			</div>
			<div>
				<p>找零总金额</p>
				<h2>{{homeInfo.count.total_outamount}}</h2>
			</div>
			<div>
				<p>总交易笔数</p>
				<h2>{{homeInfo.count.tran_count}}</h2>
			</div>
			<div>
				<p>终端总数</p>
				<h2>{{homeInfo.count.device_count}}</h2>
			</div>
		</div>
		<div class="header2-box">
			<div class="infos1">
				<div>
					<p>本月交易总金额</p>
					<h2>{{homeInfo.count.total_amount_month}}</h2>
				</div>
				<div>
					<p>本月交易总笔数</p>
					<h2>{{homeInfo.count.total_count_month}}</h2>
				</div>
				<div>
					<p>今日交易总金额</p>
					<h2>{{homeInfo.count.total_amount_today}}</h2>
				</div>
				<div>
					<p>今日交易总笔数</p>
					<h2>{{homeInfo.count.total_count_today}}</h2>
				</div>
				<div>
					<p>沉淀硬币总数量</p>
					<h2>{{homeInfo.count.count}}</h2>
				</div>
				<div>
					<p>沉淀硬币总金额</p>
					<h2>{{homeInfo.count.amount}}</h2>
				</div>
				<div>
					<p>支付宝可用备用金</p>
					<h2>{{homeInfo.count.alireserve_amount}}</h2>
				</div>
				<div>
					<p>微信可用备用金</p>
					<h2>{{homeInfo.count.wxreserve_amount}}</h2>
				</div>
			</div>
		</div>

		<div class="echbox">
			<div class="ecbix1" id="myChart"></div>
			<div class="ecbix2" id="myChart1"></div>
			<div class="ecbix1" id="myChart2"></div>
			<div class="ecbix2" id="myChart3"></div>
		</div>
	</div>
</template>

<script>
	import http from '../http'
	import '../../node_modules/echarts/map/js/china.js' // 引入中国地图数据
	export default {
		data() {
			return {
				homeInfo: {
					count: {},
					line_amount_count: [],
					device_count_province: [],
					device_count: [],
					pay_count:[]
				}
			};
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.data.count/',
				data:{}
			}).then((res) => {
				if(res.code=='200'){
					this.homeInfo = res.data;
					this.drawLine();
					this.drawLine1("myChart3", this.homeInfo.pay_count);
					this.drawLine1("myChart1", this.homeInfo.device_count);
					this.drawLine2("myChart2");
				}
			})
		},

		methods: {
			drawLine2() {
				let myChart = this.$echarts.init(document.getElementById("myChart2"));
				// 绘制图表
				let self = this;
				//数据纯属虚构
				var len10 = JSON.parse(JSON.stringify(this.homeInfo.device_count_province)).slice(0, 10)

				var yData = [];
				var barData = [];
				var len10names = {
				    '台湾省': '台湾',
				    '黑龙江省': '黑龙江',
				    '内蒙古自治区': '内蒙古',
				    "吉林省": '吉林',
				    '北京市': '北京市',
				    "辽宁省": "辽宁",
				    "河北省": "河北",
				    "天津市": "天津",
				    "山西省": "山西",
				    "陕西省": '陕西',
				    "甘肃省": '甘肃',
				    "宁夏回族自治区": '宁夏',
				    "青海省": '青海',
				    "新疆维吾尔自治区": '新疆',
				    "西藏自治区": "西藏",
				    "四川省": "四川",
				    "重庆市": "重庆市",
				    "山东省": "山东",
				    "河南省": "河南",
				    "江苏省": "江苏",
				    "安徽省": "安徽",
				    "湖北省": "湖北",
				    "浙江省": "浙江",
				    "福建省": "福建",
				    "江西省": "江西",
				    "湖南省":  "湖南",
				    "贵州省": "贵州",
				    "云南省":"云南",
				    "广东省": "广东",
				    "广西壮族自治区":  "广西",
				    "海南省": "海南",
				    '上海市': '上海',
				};
				var len20names = {
				    '台湾': '台湾省',
				    '黑龙江': '黑龙江省',
				    '内蒙古': '内蒙古自治区',
				    "吉林": '吉林省',
				    '北京市': '北京市',
				    "辽宁": "辽宁省",
				    "河北": "河北省",
				    "天津": "天津市",
				    "山西": "山西省",
				    "陕西": '陕西省',
				    "甘肃": '甘肃省',
				    "宁夏": '宁夏回族自治区',
				    "青海": '青海省',
				    "新疆": '新疆维吾尔自治区',
				    "西藏": "西藏自治区",
				    "四川": "四川省",
				    "重庆市": "重庆市",
				    "山东": "山东省",
				    "河南": "河南省",
				    "江苏": "江苏省",
				    "安徽": "安徽省",
				    "湖北": "湖北省",
				    "浙江": "浙江省",
				    "福建": "福建省",
				    "江西": "江西省",
				    "湖南":  "湖南省",
				    "贵州": "贵州省",
				    "云南":"云南省",
				    "广东": "广东省",
				    "广西":  "广西壮族自治区",
				    "海南": "海南省",
				    '上海市': '上海市',
				};
				
				var data = this.homeInfo.device_count_province.map((res) => {
					return {
						name: len10names[res.name],
						value: res.value
					}
				})

				for (var i = 0; i < len10.length; i++) {
					barData.push(data[i]);
					yData.push(i + data[i].name);
				}
				
				
				

				var option = {
					title: [{
						show: true,
						text: "设备部署情况",
						textStyle: {
							color: "rgba(0, 0, 0, 0.65)",
							fontSize: 16,
						},
						left: '2%',
						top: '4%',
					}, ],
					tooltip: {
						show: true,
						formatter: function(params) {
							if (params.data) {
								return len20names[params.name] + "：" + params.data["value"];
							} else {
								return len20names[params.name]
							}
						},
					},
					visualMap: {
						type: "continuous",
						orient: "horizontal",
						itemWidth: 10,
						itemHeight: 80,
						text: ["高", "低"],
						show: false,
						showLabel: true,
						seriesIndex: [0],
						 min: 0,
						        max: 100,
						inRange: {
							color: [ "#D2EAFF", "#8AC6FD", "#45A5F8"],
						},
						textStyle: {
							color: "#7B93A7",
						},
						bottom: 30,
						left: "left",
					},
					grid: {
						left: '18%',
						top: '17%',
						bottom: '10%',
						width: "30%",
					},
					xAxis: {
						show: false,
					},
					yAxis: {
						type: "category",
						inverse: true,
						nameGap: 16,
						axisLine: {
							show: false,
							lineStyle: {
								color: "#ddd",
							},
						},
						axisTick: {
							show: false,
							lineStyle: {
								color: "#ddd",
							},
						},
						axisLabel: {
							interval: 0,
							margin: 85,
							textStyle: {
								color: "#455A74",
								align: "left",
								fontSize: 14,
							},
							rich: {
								a: {
									color: "#fff",
									backgroundColor: "#FAAA39",
									width: 20,
									height: 20,
									align: "center",
									borderRadius: 2,
								},
								b: {
									color: "#fff",
									backgroundColor: "#4197FD",
									width: 20,
									height: 20,
									align: "center",
									borderRadius: 2,
								},
							},
							formatter: function(params) {
								if (parseInt(params.slice(0, 1)) < 3) {
									return [
										"{a|" +
										(parseInt(params.slice(0, 1)) + 1) +
										"}" +
										"  " + len20names[params.slice(1)],
									].join("\n");
								} else {
									return [
										"{b|" +
										(parseInt(params.slice(0, 1)) + 1) +
										"}" +
										"  " + len20names[params.slice(1)],
									].join("\n");
								}
							},
						},
						data: yData,
					},
					geo: {
						// roam: true,
						map: "china",
						left: "left",
						left: "50%",
						// layoutSize: '80%',
						label: {
							emphasis: {
								show: false,
							},
						},
						itemStyle: {
							emphasis: {
								areaColor: "#fff464",
							},
						},
					},
					series: [{
							name: "mapSer",
							type: "map",
							roam: false,
							geoIndex: 0,
							label: {
								show: false,
							},
							data: data,
						},
						{
							name: "barSer",
							type: "bar",
							roam: false,
							visualMap: false,
							zlevel: 2,
							barMaxWidth: 8,
							barGap: 0,
							itemStyle: {
								normal: {
									color: function(params) {
										// build a color map as your need.
										var colorList = [{
												colorStops: [{
														offset: 0,
														color: "#FFD119", // 0% 处的颜色
													},
													{
														offset: 1,
														color: "#FFAC4C", // 100% 处的颜色
													},
												],
											},
											{
												colorStops: [{
														offset: 0,
														color: "#00C0FA", // 0% 处的颜色
													},
													{
														offset: 1,
														color: "#2F95FA", // 100% 处的颜色
													},
												],
											},
										];
										if (params.dataIndex < 3) {
											return colorList[0];
										} else {
											return colorList[1];
										}
									},
									barBorderRadius: 15,
								},
							},
							data: barData,
						},
					],
				};
				myChart.setOption(option);
			},
			drawLine() {
				// 基于准备好的dom，初始化echarts实例
				console.log(this.$echarts);
				let myChart = this.$echarts.init(document.getElementById("myChart"));
				// 绘制图表
				let self = this;
				let  xAxis = [];
				let bar = []
				let line = []
				this.homeInfo.line_amount_count.forEach((v) => {
					xAxis.push(v.name)
					bar.push(v.data1)
					line.push(v.data2)
				})
				var option = {
					backgroundColor: "#fff",
					title: [{
						show: true,
						text: "最近一个月交易数据",
						textStyle: {
							color: "rgba(0, 0, 0, 0.65)",
							fontSize: 16,
						},
						left: '2%',
						top: '4%',
					}, ],
					tooltip: {
						show: true,
						trigger: 'axis',
						formatter: function(params) {
							return `<div><p>${params[0].axisValue}</p><p>${params[0].seriesName} : ${params[0].value}</p><p>${params[1].seriesName} : ${params[1].value}</p></div>`
						},
					},
					grid: {
						top: "24%",
						right: "5%",
						left: "5%",
						bottom: "18%",
					},
					legend: {
						top: "4%",
						right: "5%",
						itemWidth: 6,
						itemGap: 20,
						textStyle: {
							color: "#556677",
						},
					},
					xAxis: [{
						type: "category",
						color: "#59588D",
						data: xAxis,
						axisLabel: {
							margin: 20,
							color: "#999",
							textStyle: {
								fontSize: 14,
							},
						},
						axisLine: {
							lineStyle: {
								color: "rgba(107,107,107,0.37)",
							},
						},
						axisTick: {
							show: false,
						},
					}, ],
					yAxis: [{
						name: '交易笔数',
						axisLabel: {
							formatter: "{value}",
							color: "#999",
							textStyle: {
								fontSize: 14,
							},
						},
						axisLine: {
							lineStyle: {
								color: "rgba(107,107,107,0.37)",
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							lineStyle: {
								color: "rgba(131,101,101,0.2)",
								type: "dashed",
							},
						},
					},{
						name: '交易金额',
						axisLabel: {
							formatter: "{value}",
							color: "#999",
							textStyle: {
								fontSize: 14,
							},
						},
						axisLine: {
							lineStyle: {
								color: "rgba(107,107,107,0.37)",
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							lineStyle: {
								color: "rgba(131,101,101,0.2)",
								type: "dashed",
							},
						},
					}, ],
					series: [{
							data: line,
							type: "line",
							smooth: true,
							name: "交易笔数",
							color: "#3275FB",
							
							symbol: "none",
							lineStyle: {
								color: "#3275FB",
								width: 8,
								shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
								shadowBlur: 15,
								shadowOffsetY: 20,
							},
						},{
							type: "bar",
							name: "交易金额",
							data: bar,
							color: "#FF9A22",
							yAxisIndex: 1,
							barWidth: "25px",
							itemStyle: {
								normal: {
									color: function(params) {
										let colorArr = ["#FFD56E", "#FF9A22"];
										return new self.$echarts.graphic.LinearGradient(
											0,
											0,
											0,
											1,
											[{
													offset: 0,
													color: colorArr[0], // 0% 处的颜色
												},
												{
													offset: 1,
													color: colorArr[1], // 100% 处的颜色
												},
											],
											false
										);
									},
									barBorderRadius: [4, 4, 0, 0],
								},
							},
							label: {
								normal: {
									show: false,
									fontSize: 18,
									fontWeight: "bold",
									color: "#333",
									position: "top",
								},
							},
						},
						
					],
				};
				myChart.setOption(option);
			},
			drawLine1(id, data) {
				let myChart = this.$echarts.init(document.getElementById(id));
				// 绘制图表
				let self = this;
				var colorList = [
					"#73DDFF",
					"#73ACFF",
					"#FDD56A",
					"#FDB36A",
					"#FD866A",
					"#9E87FF",
					"#58D5FF",
				];
				
				if (id == 'myChart1') {
					colorList.reverse()
				}
				var total = 0
				data.forEach(v => {
					total += parseInt(v.value)
				})
				
				function Percentage(num) { 
				    if (num == 0 || total == 0){
				        return 0;
				    }
				    return (Math.round(num / total * 10000) / 100.00);// 小数点后两位百分比
				}
				
				var option = {
					// title: {
					//     text: '{header1| 名称与占比}' +
					//         '{header2| 数量}' ,
					//     textAlign: 'left',
					//     left: '49%',
					//     top: '30%',
					//     textStyle: {
					//         color: '#ccc',
					//         rich: {
					//             header1: {
					//                 width: 130,
					//                 fontSize: 15
					//             },
					//             header2: {
					//                 width: 85,
					//                 fontSize: 15
					//             },
					//             header3: {
					//                 fontSize: 15
					//             }
					//         }
					//     }
					// },
					title: [{
						show: true,
						text: id == 'myChart1' ? '终端交易额排名TOP10' : "渠道交易情况",
						textStyle: {
							color: "rgba(0, 0, 0, 0.65)",
							fontSize: 16,
						},
						left: '2%',
						top: '4%',
					}, ],
					legend: {
						// selectedMode: false, // 取消图例上的点击事件
						type: "plain",
						icon: "circle",
						orient: "vertical",
						left: "44%",
						top: "center",
						align: "left",
						itemGap: 15,
						itemWidth: 10, // 设置宽度
						itemHeight: 10, // 设置高度
						symbolKeepAspect: false,
						textStyle: {
							color: "#000",
							rich: {
								name: {
									verticalAlign: "right",
									align: "left",
									width: 160,
									fontSize: 12,
								},
								value: {
									align: "left",
									width: 60,
									fontSize: 12,
								},
								count: {
									align: "left",
									width: 80,
									fontSize: 12,
								},
								upRate: {
									align: "left",
									fontSize: 12,
									color: "#00cf90",
								},
								downRate: {
									align: "left",
									fontSize: 12,
									color: "#ff5722",
								},
							},
						},
						data: data.map((item) => item.name),
						formatter: function(name) {
							let className = "upRate";
							let rateIcon = "▲";
							if (data && data.length) {
								for (var i = 0; i < data.length; i++) {
									if (name === data[i].name) {
										if (data[i].rate < 0) {
											className = "downRate";
											rateIcon = "▼";
										}
										return (
											"{name| " +
											name +
											"}   " +
											"{value|   " +
											Percentage(data[i].value) +
											"%}" +
											"{count| " +
											data[i].value +
											"} "
										);
									}
								}
							}
						},
					},
					series: [{
						name: "数量",
						type: "pie",
						radius: ["40%", "55%"],
						center: ["25%", "55%"],
						data: data,
						label: {
							normal: {
								show: false,
								position: "center",
								formatter: "{text|{c}}\n{b}",
								rich: {
									text: {
										align: "center",
										verticalAlign: "middle",
										padding: 8,
										fontSize: 30,
									},
									value: {
										align: "center",
										verticalAlign: "middle",
										fontSize: 20,
									},
								},
							},
							emphasis: {
								show: true,
								textStyle: {
									fontSize: "12",
								},
							},
						},
						itemStyle: {
							normal: {
								color: function(params) {
									return colorList[params.dataIndex];
								},
							},
						},
						labelLine: {
							normal: {
								show: true,
							},
						},
					}, ],
				};
				myChart.setOption(option);
			},
		},
	};
</script>
<style lang="less">
	.home-page {
		.header1-box {
			background-color: #fff;
			height: 130px;
			display: flex;
			justify-content: space-between;
			padding: 0 30px;
			box-sizing: border-box;
			align-items: center;
			border-radius: 5px;

			div {
				width: 22%;
				height: 100px;
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				justify-content: center;
				padding: 0 20px;
				border-radius: 5px;

				&:nth-child(1) {
					background: #73ACFF;
				}

				&:nth-child(2) {
					background: #FD866A;
				}

				&:nth-child(3) {
					background: #9E87FF;
				}

				&:nth-child(4) {
					background: #FFA54D;
				}

				p {
					font-size: 14px;
					line-height: 18px;
					margin: 0px;
					font-weight: 600;
					color: #fff;
				}

				h2 {
					font-size: 28px;
					color: #fff;
					margin: 0px;
					margin-top: 2px;
				}
			}
		}

		.header2-box {
			margin-top: 24px;
			background-color: #fff;
			padding-bottom: 20px;
			border-radius: 5px;

			.infos1 {
				display: flex;
				height: 120px;
				padding-top: 20px;

				div {
					width: 20%;
					height: 100px;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					padding: 0 20px;

					p {
						font-size: 14px;
						line-height: 18px;
						margin: 0px;
						font-weight: 700;
					}

					h2 {
						font-size: 28px;
						font-weight: 700;
						color: #1890ff;
						margin: 0px;
						margin-top: 2px;
					}
				}
			}

			.infos2 {
				display: flex;
				height: 100px;
				box-shadow: 0px 4px 11px 0px #b4b3b454;
				margin: 0 30px;
				border-radius: 10px;

				div {
					width: 20%;
					height: 100px;
					display: flex;
					align-items: center;
					flex-direction: column-reverse;
					justify-content: center;
					padding: 0 20px;
					border-right: 1px solid #b4b3b454;

					p {
						font-size: 14px;
						line-height: 18px;
						margin: 0px;
						font-weight: 700;
					}

					h2 {
						font-size: 24px;
						font-weight: 700;
						margin: 0px;
						margin-top: 2px;
						color: rgba(0, 0, 0, 0.65);
					}
				}
			}
		}
	}

	.echbox {
		display: flex;
		flex-wrap: wrap;
		margin-top: 24px;
		justify-content: space-between;
	}

	.ecbix1 {
		width: 60%;
		height: 300px;
		border-radius: 5px;
		background-color: #fff;
		overflow: hidden;
		margin-bottom: 24px;
	}

	.ecbix2 {
		width: calc(40% - 24px);
		margin-left: 24px;
		height: 300px;
		border-radius: 5px;
		background-color: #fff;
		overflow: hidden;
		margin-bottom: 24px;
	}
</style>
